export enum Links {
  company = '/company',
  detailing = '/detailing',
  learning = '/learning',
  information = '/information',
  users = '/users',
  departments = '/departments',
  presentations = '/presentations',
  organisations = '/organisations',
  clients = '/clients',
  statistics = '/statistics',
}

export type Page = {
  name: string;
  href: string;
};

export interface NavigateLink extends Page {
  subPages: Page[];
}
