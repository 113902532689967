export const lexics = {
  common: {
    save: 'Сохранить',
    delete: 'Удалить',
    cancel: 'Отменить',
  },

  api: {
    response: {
      default: {
        success: '',
        error:
          'Соединение с удаленным сервером прервано. Проверьте интернет соединение',
      },

      clientOrganisation: {
        multi_update: {
          success: 'Организации были успешно изменены',
          error:
            'При изменении организаций возникли проблемы, попробуйте позже',
        },
        update: {
          success: 'Организация была успешно изменена',
          error:
            'При изменении организации возникли проблемы, попробуйте позже',
        },
        create: {
          success: 'Новая организация была успешно cоздана',
          error:
            'При создании новой организации возникли проблемы, попробуйте позже',
        },
      },

      client: {
        multi_update: {
          success: 'Клиенты были успешно изменены',
          error: 'При изменении клиентов возникли проблемы, попробуйте позже',
        },
        update: {
          success: 'Клиент был успешно изменен',
          error: 'При изменении клиента возникли проблемы, попробуйте позже',
        },
        create: {
          success: 'Новый клиент был успешно cоздан',
          error:
            'При создании нового клиента возникли проблемы, попробуйте позже',
        },
      },
    },
  },

  information: {
    noData: 'нет данных',
  },

  detailing: {
    presentations: {
      title: 'Список презентаций',

      buttons: {
        add: 'Добавить презентацию',
      },
    },

    clients: {
      title: 'Список клиентов',
      buttons: {
        add: 'Добавить клиентов в .xls',
      },
      modal: {
        delete: 'Вы действительно хотите удалить выбранных клиентов?',
      },
    },

    organisations: {
      title: 'Список организаций',
      buttons: {
        add: 'Импорт организаций из .xls',
      },

      modal: {
        delete:
          'Вы действительно хотите удалить выбранные организации и всех клиентов, которые привязаны к ним?',
      },
    },

    organisation: {
      subtitle: {
        contacts: 'Контактная информация',
        status: 'Статус организации',
        address: 'Адрес',
      },

      button: {
        statistics: 'Статистика по организации',
      },

      modal: {
        save: 'Вы действительно хотите сохранить данные организации?',
        delete: 'Вы действительно хотите удалить организацию?',
        users:
          'Вместе с организацией удалятся все клиенты, которые к ней привязаны.',
      },
    },

    client: {
      subtitle: {
        personal: 'Личная информация',
        contacts: 'Контактная информация',
        status: 'Статус клиента',
      },

      button: {
        statistics: 'Статистика по клиенту',
      },

      modal: {
        save: 'Вы действительно хотите сохранить данные клиента?',
        delete: 'Вы действительно хотите удалить клиента?',
      },
    },

    statistics: {
      title: 'Статистика Maven Detailing',
      buttons: {
        xls: 'Выгрузить статистику в .xls',
        pdf: 'Выгрузить статистику в .pdf',
      },
    },
  },
};
