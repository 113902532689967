import { User } from 'types/user';

interface GetCompanyAccessLinkProps {
  userInformation?: User;
  project: 'Learning' | 'Detailing';
}

export const getCompanyAccessLink = (props: GetCompanyAccessLinkProps) => {
  const { userInformation, project } = props;

  if (!userInformation) return '';

  const { organisation, email, firstName } = userInformation;

  return `https://mavenbase.typeform.com/request-access#company=${organisation.name}&interested_in=${project}&mail=${email}&name=${firstName}`;
};

export const getRequestFeatureLink = (userInformation: User) => {
  const { firstName, email, organisation } = userInformation;

  return `https://mavenbase.typeform.com/request-feature#name=${firstName}&mail=${email}&company=${organisation.name}`;
};
