import * as React from 'react';

const DownloadIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
      <path
        stroke="currentColor"
        d="M6 6.5H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-10a1 1 0 0 0-1-1h-3"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 2.5v12m2-12v12"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        d="m14.75 11.25-4.093 4.093a1 1 0 0 1-1.414 0L5.149 11.25"
      />
    </svg>
  );
};

export default DownloadIcon;
