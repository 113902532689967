import { useDispatch } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import modalsReducer from 'reducers/modals';
import organisationReducer from 'reducers/organisationSlice';
import api from 'store/api';

const store = configureStore({
  reducer: {
    modals: modalsReducer,
    organisation: organisationReducer,

    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([api.middleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

setupListeners(store.dispatch);

export default store;
