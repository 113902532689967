export enum ArrowLongIconDirectionEnum {
  left = 'left',
  right = 'right',
}

export interface IconProps {
  className?: string;
}

export enum AllPsychotype {
  D = 'D',
  I = 'I',
  S = 'S',
  C = 'C',
  DI = 'DI',
  DS = 'DS',
  DC = 'DC',
  IS = 'IS',
  CS = 'CS',
  CI = 'CI',
}

export type BasicPsychotype =
  | AllPsychotype.D
  | AllPsychotype.I
  | AllPsychotype.C
  | AllPsychotype.S;
