import { AppProps } from 'next/app';
import Head from 'next/head';
import { ReactNode } from 'react';
import { Provider } from 'react-redux';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ThemeProvider from 'provides/theme';
import { rootStyles } from 'provides/theme/const';

import store from 'store';

import AlertInfo from 'components/alert-info';
import Layout from 'components/layout';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'styles/fonts.scss';
import 'styles/global.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 300000,
    },
  },
});

interface AppPropsExtends extends AppProps {
  Component: AppProps['Component'] & { children?: ReactNode };
}

const App = ({ Component, pageProps }: AppPropsExtends) => {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"
        />
      </Head>

      <div
        style={{
          ...rootStyles,
          height: 'calc(100% - 100px)',
        }}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <ThemeProvider>
              <Layout>
                <AlertInfo />

                <Component {...pageProps} />
              </Layout>
            </ThemeProvider>
          </Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </div>
    </>
  );
};

export default App;
