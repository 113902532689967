import { Nullable } from 'types/common';

import { NamesSearch, Params } from './type';

export const getCompanyQuery = (id: Nullable<string> = '') => {
  return `/organisations/admin/${id}`;
};

export const getParams = (params: Params) => {
  const keys = Object.keys(params) as NamesSearch[];

  return keys.reduce<Params>((accumulator, key) => {
    const value = params[key];

    if (!value) return accumulator;

    return {
      ...accumulator,
      [key]: value,
    };
  }, {});
};
