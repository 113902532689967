import * as React from 'react';

import { IconButton } from '@mui/material';
import LibraryButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { PopupButton } from '@typeform/embed-react';
import cn from 'classnames';

import useCustomRouter from 'hooks/useCustomRouter';

import icons from 'ui-kit/icons';

import { style } from './style';
import { ButtonProps, ButtonVariant } from './type';

import styles from './button.module.scss';

const { DownloadIcon, BackIcon, SaveIcon } = icons;

const Button: React.FC<ButtonProps> = (props) => {
  const {
    className,
    isActive = true,
    popupId,
    actionVariant = ButtonVariant.default,
    icon,
    ...restProps
  } = props;

  const icons: {
    [k in ButtonVariant]?: JSX.Element;
  } = {
    [ButtonVariant.save]: <SaveIcon />,
    [ButtonVariant.download]: <DownloadIcon />,
  };

  const classButton = cn(className, styles.button, {
    [styles.buttonActive]: isActive,
  });

  if (popupId) {
    return (
      <PopupButton style={style} className={classButton} id={popupId}>
        {restProps.children}
      </PopupButton>
    );
  }

  if (icon) {
    return (
      <IconButton {...restProps} sx={style} className={classButton}>
        {icon}
      </IconButton>
    );
  }

  return (
    <LibraryButton
      sx={style}
      startIcon={icons[actionVariant]}
      {...restProps}
      className={classButton}
    />
  );
};

const ButtonBack: React.FC<Omit<ButtonProps, 'children'>> = (props) => {
  const { onClick, redirectUrl } = props;

  const { back, push } = useCustomRouter();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick?.(event);

    if (redirectUrl) {
      push(redirectUrl);
    } else {
      back();
    }
  };

  return (
    <Button
      startIcon={<BackIcon />}
      className={styles.button}
      onClick={handleClick}
      {...props}>
      Назад
    </Button>
  );
};

const styledButton = styled(Button);
const styledButtonBack = styled(ButtonBack);

export const ButtonOutlined = styledButton(
  ({
    theme: {
      button: { blueLight, blue },
    },
  }) => {
    return {
      padding: '10px 15px',

      color: blue,
      backgroundColor: 'white',

      textTransform: 'none',

      lineHeight: 'inherit',

      border: '1px solid',

      fontWeight: 700,

      ':hover': {
        backgroundColor: 'white',
      },
      ':disabled': {
        color: blueLight,
      },
    };
  },
);

export const ButtonBackOutlined = styledButtonBack(
  ({
    theme: {
      button: { blue },
    },
  }) => {
    return {
      color: blue,
      backgroundColor: 'white',

      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: blue,

      lineHeight: 'inherit',

      ':hover': {
        backgroundColor: 'white',
      },
    };
  },
);

export const ButtonBlue = styled(Button)(
  ({
    theme: {
      button: { blue },
    },
  }) => ({
    color: 'white',
    backgroundColor: blue,

    lineHeight: 'inherit',

    ':hover': {
      backgroundColor: blue,
    },
  }),
);

export default Button;
