import { FC } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { ButtonColors } from 'ui-kit/button/const';
import { ButtonColors as IButtonColors } from 'ui-kit/button/type';

import { OptionalFields } from 'types/utils';

import { fontSize, themeColors } from './const';
import { ThemeColors } from './type';

declare module '@mui/material/styles' {
  interface Theme {
    button: IButtonColors;
    colors: ThemeColors;
  }

  interface ThemeOptions {
    button?: OptionalFields<IButtonColors>;
    colors?: OptionalFields<ThemeColors>;
  }
}

export const theme = createTheme({
  button: ButtonColors,

  spacing: 10,

  colors: themeColors,

  typography: {
    fontFamily: 'Montserrat',
  },
});

theme.typography.h2 = {
  fontSize: fontSize['h2'],
  fontWeight: theme.typography.fontWeightBold,
};

theme.typography.h3 = {
  fontSize: fontSize['h3'],
  fontWeight: theme.typography.fontWeightBold,
};

theme.typography.h4 = {
  fontSize: fontSize['h4'],
  fontWeight: theme.typography.fontWeightBold,
};

theme.typography.h5 = {
  fontSize: fontSize['h5'],
  fontWeight: theme.typography.fontWeightBold,
};

const Provider: FC = (props) => {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Provider;
