import * as React from 'react';

const Icon: React.FC = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.81296" cy="7.56101" r="6.61765" stroke="currentColor" />
    <path d="M7.81299 4.91406V10.2082" stroke="currentColor" />
    <path d="M10.46 7.56104L5.16584 7.56104" stroke="currentColor" />
  </svg>
);

export default Icon;
