import { CSSProperties } from 'react';

import { SxProps } from '@mui/material';

import { ThemeColors } from './type';

export const fontSize = {
  h2: 36,
  h3: 24,
  h4: 20,
  h5: 18,
  h6: 16,
  medium: 14,
  normal: 12,
  small: 10,
};

export const colors = {
  invisibleColor: 'rgba(0,0,0,0)',
  blackColor: 'black',
  mainBrownColor: '#808080',
  mainGrayColor: '#D1D1D1',
  darkGrayColor: '#666666',
  mainBlueColor: '#00D1FF',
  lightBlueColor: '#BDE3FF',
};

export const themeColors: ThemeColors = {
  black: colors.blackColor,

  gray: colors.mainGrayColor,
  darkGray: colors.darkGrayColor,

  invisible: colors.invisibleColor,
};

const fontSizeKeys = Object.keys(fontSize) as (keyof typeof fontSize)[];
const colorsKeys = Object.keys(colors) as (keyof typeof colors)[];

export const rootColors = colorsKeys.reduce((accum, key) => {
  return {
    ...accum,
    [`--${key}`]: colors[key],
  };
}, {});
export const rootFontSize = fontSizeKeys.reduce((accum, key) => {
  return {
    ...accum,
    [`--fontSize__${key}`]: `${fontSize[key]}px`,
  };
}, {});

export const rootStyles = {
  ...rootColors,
  ...rootFontSize,
} as CSSProperties;

export const borderBlack = `2px solid ${themeColors.black}`;
export const borderGray = `2px solid ${themeColors.gray}`;

type flexFields = 'row' | 'column';

export const fullSize: SxProps = {
  width: '100%',
  height: '100%',
};

export const styleFlex: {
  [k in flexFields]: SxProps;
} = {
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
};

interface GridStyleProps {
  numberColumn: number;
  rowGap?: string;
  columnGap?: string;
}

export const getGridStyle = (props: GridStyleProps): SxProps => {
  const { numberColumn, rowGap = 0, columnGap = rowGap } = props;

  return {
    display: 'grid',
    gridTemplateColumns: `repeat(${numberColumn}, 1fr)`,
    rowGap,
    columnGap,
  };
};
