import * as React from 'react';

const SaveIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" fill="none">
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        d="M.5 1.5A.5.5 0 0 1 1 1h13.586a.5.5 0 0 1 .353.146l2.415 2.415a.5.5 0 0 1 .146.353V17.5a.5.5 0 0 1-.5.5H1a.5.5 0 0 1-.5-.5v-16Z"
      />
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        d="M4 1.5v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-5M4 17.5v-5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v5M9 2.5v3"
      />
    </svg>
  );
};

export default SaveIcon;
