import * as React from 'react';

import cn from 'classnames';

import { CardSize } from './type';

import styles from './card.module.scss';

interface CardProps {
  className?: string;
  size?: CardSize;
}

const Card: React.FC<CardProps> = (props) => {
  const { size = 'M', children } = props;

  const className = cn(
    styles.card,
    styles[`cardSize_${size}`],
    props.className,
  );

  return (
    <div {...props} className={className}>
      {children}
    </div>
  );
};

export default Card;
