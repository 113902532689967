import { NextRouter, useRouter } from 'next/router';

import { NamesSearch } from 'store/api/type';

export type CustomRouter = Omit<NextRouter, 'query'> & {
  query: QueryParams;
};

export enum Orders {
  asc = 'asc',
  desc = 'desc',
}

export type QueryParams = {
  [k in NamesSearch]?: string;
} & {
  organisationTags?: string | string[];
  clientTags?: string | string[];
};

const useCustomRouter = () => {
  const router: CustomRouter = useRouter();

  return router;
};

export default useCustomRouter;
