import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrganisationType } from 'types/organisation';

export type OrganisationInfoType = Pick<OrganisationType, 'id'>;

const initialState: OrganisationInfoType = {
  id: '',
};

export const organisationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganisationInfo(
      state,
      { payload }: PayloadAction<OrganisationInfoType>,
    ) {
      state.id = payload.id;
    },
    setOrganisationReset(state) {
      state.id = '';
    },
  },
});
export const { setOrganisationInfo, setOrganisationReset } =
  organisationSlice.actions;

export default organisationSlice.reducer;
