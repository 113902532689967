import * as React from 'react';

const Icon: React.FC = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.07793 17.6707H14.9682C17.0266 17.6707 18.0461 16.6735 18.0461 14.6984V2.99135C18.0461 1.0162 17.0266 0.019043 14.9682 0.019043H3.07793C1.02924 0.019043 0 1.00661 0 2.99135V14.6984C0 16.6831 1.02924 17.6707 3.07793 17.6707ZM5.73436 12.8575C5.28345 12.8575 4.93057 12.5027 4.93057 12.0617C4.93057 11.8507 5.00899 11.6494 5.16582 11.5056L7.88106 8.84005L5.16582 6.18418C5.00899 6.03074 4.93057 5.83901 4.93057 5.62809C4.93057 5.17743 5.28345 4.84184 5.73436 4.84184C5.95981 4.84184 6.13625 4.91855 6.29312 5.06237L9.02797 7.72787L11.7824 5.05278C11.949 4.88978 12.1353 4.82267 12.3412 4.82267C12.7921 4.82267 13.1547 5.16784 13.1547 5.60889C13.1547 5.82941 13.0861 6.00199 12.9195 6.17458L10.1846 8.84005L12.9097 11.496C13.0665 11.6398 13.1449 11.8411 13.1449 12.0617C13.1449 12.5027 12.7822 12.8575 12.3215 12.8575C12.1059 12.8575 11.8902 12.7807 11.7432 12.6274L9.02797 9.96189L6.32251 12.6274C6.16564 12.7807 5.95981 12.8575 5.73436 12.8575Z"
        fill="#00D1FF"
      />
    </svg>
  );
};

export default Icon;
