import * as React from 'react';

const Icon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" fill="none">
      <path
        stroke="#00D1FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.153 9.026 1 4.873 5.153.72"
      />
      <path stroke="#00D1FF" strokeLinecap="round" d="M1.326 4.873H15" />
    </svg>
  );
};

export default Icon;
