import * as React from 'react';

import { IconProps } from './types';

const Icon: React.FC<IconProps> = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none">
      <rect
        width="19"
        height="19"
        x=".5"
        y=".5"
        stroke="currentColor"
        rx="3.5"
      />
      <path
        fill="currentColor"
        d="M4.742 9.314c-.63-.63-.184-1.708.707-1.708h9.314c.891 0 1.337 1.078.707 1.708l-4.657 4.656a1 1 0 0 1-1.414 0L4.742 9.314Z"
      />
    </svg>
  );
};

export default Icon;
