import { AllPsychotype } from 'ui-kit/icons/types';

export const getLetters = (psychotypes: AllPsychotype[]) => {
  return psychotypes.reduce((text, psychotype) => {
    return text + psychotype;
  }, '');
};

export const getHumanDate = (date: Date | string): string =>
  new Date(date).toLocaleDateString();
