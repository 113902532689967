import * as React from 'react';

export const style: React.CSSProperties = {
  padding: '10px 15px',
  textTransform: 'none',

  fontSize: 'var(--fontSize__h6)',
  lineHeight: '1.3',
  fontWeight: 'bold',
  borderRadius: '6px',
};
