import { CSSProperties, FC } from 'react';

import { basicPsychotypes } from 'data/psychotypes';

import { getLetters } from 'utils/helper';

import { AllPsychotype } from './types';

import styles from './segments-icons.module.scss';

type SegmentIconType = {
  psychotypes: AllPsychotype[];
};

const SegmentIcon: FC<SegmentIconType> = (props) => {
  const { psychotypes } = props;

  return (
    <div className={styles.fragments}>
      {basicPsychotypes.map((psychotype) => {
        const { color, psychotypes: _psychotypes, svgPath } = psychotype;

        const style: CSSProperties = {
          fill: color,
        };

        const checkFillColor = psychotypes.some(
          (psychotype) => psychotype === _psychotypes[0],
        );

        return (
          <svg
            key={color}
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23">
            <path
              d={svgPath}
              stroke={color}
              strokeWidth="1"
              fill="none"
              style={checkFillColor ? style : {}}
            />
          </svg>
        );
      })}
      <div className={styles.fragmentsLetter}>
        {basicPsychotypes.map((psychotype) => {
          const { color, psychotypes: _psychotypes } = psychotype;

          const isShowLetter = psychotypes.some(
            (psychotype) => psychotype === _psychotypes[0],
          );

          if (!isShowLetter) return null;

          return (
            <span
              key={color}
              style={{
                color,
              }}>
              {getLetters(_psychotypes)}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default SegmentIcon;
