import * as React from 'react';

const PlusIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
        d="M9.535 1.071V18M18 9.535H1.071"
      />
    </svg>
  );
};

export default PlusIcon;
