import { AllPsychotype } from 'ui-kit/icons/types';

export interface Information {
  psychotypes: AllPsychotype[];
  color?: string;
  svgPath?: string;
}

type PsychotypeInformation = {
  [k in AllPsychotype]: Information;
};

const psychotypeInformation: PsychotypeInformation = {
  [AllPsychotype.D]: {
    psychotypes: [AllPsychotype.D],
    color: '#E7374C',
    svgPath:
      'M7.118 7.118A24.539 24.539 0 0 1 22.628 0v7.39A17.174 17.174 0 0 0 7.392 22.628H0a24.54 24.54 0 0 1 7.118-15.51z',
  },
  [AllPsychotype.I]: {
    psychotypes: [AllPsychotype.I],
    color: '#E5DF35',
    svgPath:
      'M11.264 12.488a18.028 18.028 0 0 0-5.763-3.874L5.5 8.612l-.092-.038A18.03 18.03 0 0 0 .88 7.358V.795A24.578 24.578 0 0 1 21.2 15.847a24.564 24.564 0 0 1 1.755 7.023h-6.563a18.054 18.054 0 0 0-5.129-10.382z',
  },
  [AllPsychotype.S]: {
    psychotypes: [AllPsychotype.S],
    color: '#3BA48A',
    svgPath:
      'M11.022 11.024a17.644 17.644 0 0 0 3.7-5.43h.005l.125-.301c.59-1.422.988-2.912 1.19-4.43h6.424a24.064 24.064 0 0 1-6.93 14.674A24.041 24.041 0 0 1 .86 22.466v-6.424a17.67 17.67 0 0 0 10.161-5.02z',
  },
  [AllPsychotype.C]: {
    psychotypes: [AllPsychotype.C],
    color: '#3E69B7',
    svgPath:
      'M22.138 22.467a24.064 24.064 0 0 1-14.674-6.93A24.042 24.042 0 0 1 .534.862h6.423a17.67 17.67 0 0 0 5.02 10.161 17.66 17.66 0 0 0 10.16 5.02v6.423z',
  },
  [AllPsychotype.DI]: {
    psychotypes: [AllPsychotype.D, AllPsychotype.I],
  },
  [AllPsychotype.DS]: {
    psychotypes: [AllPsychotype.D, AllPsychotype.S],
  },
  [AllPsychotype.DC]: {
    psychotypes: [AllPsychotype.D, AllPsychotype.C],
  },
  [AllPsychotype.IS]: {
    psychotypes: [AllPsychotype.I, AllPsychotype.S],
  },
  [AllPsychotype.CS]: {
    psychotypes: [AllPsychotype.C, AllPsychotype.S],
  },
  [AllPsychotype.CI]: {
    psychotypes: [AllPsychotype.C, AllPsychotype.I],
  },
};

export const basicPsychotypes = [
  psychotypeInformation[AllPsychotype.D],
  psychotypeInformation[AllPsychotype.I],
  psychotypeInformation[AllPsychotype.C],
  psychotypeInformation[AllPsychotype.S],
];

export default psychotypeInformation;
